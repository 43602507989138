<template>
  <v-app>
    <!-- <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles> -->
    <v-container class="container">
      <v-card class="grid" :elevation="11" width="95%">
        <v-card-title
          class="text-center justify-center title"
          style="white-space: normal !important"
        >
          TOTAL SALES TILL DATE {{ currentDate }} IS RM {{ totalSales }}
        </v-card-title>
        <!-- <v-responsive :aspect-ratio="4/3"> -->
        <v-tabs bg-color="#FFFFFFC9" color="#80ff00 " grow>
          <v-tab @click="loadTab('Agreements')"> Agreements </v-tab>
          <v-tab @click="loadTab('Available Cars')"> Available Cars </v-tab>
          <v-tab @click="loadTab('Users')"> Users </v-tab>
          <v-tab @click="loadTab('Cars')"> Cars </v-tab>
          <!-- <v-tab @click="loadTab('Bookings')"> Bookings </v-tab> -->
          <!-- <v-tab @click="loadTab('Expenses')"> Expenses </v-tab> -->
          <v-tab @click="loadTab('Trackers')"> Trackers </v-tab>
          <v-tab @click="loadTab('Marketting')"> Marketing Tools </v-tab>
        </v-tabs>
        <!-- </v-responsive> -->
        <div v-if="showAgreements">
          <v-card class="grid" color="transparent">
            <v-row align="center">
              <v-col :cols="4" align="center"
                ><v-btn dark class="button" @click="createPdf()">
                  <v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >
                    mdi-card-plus-outline mdi-18px
                  </v-icon>
                  Create Agreement
                </v-btn></v-col
              >
              <v-col :cols="4" align="center">
                <v-btn dark class="button" @click="advancedFilter">
                  <v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >
                    mdi-card-plus-outline mdi-18px
                  </v-icon>
                  Advanced Filter
                </v-btn></v-col
              >
            </v-row>
            <v-row align="center" justify="center" style="margin-bottom: 1%">
              <v-col :cols="6" align="center" justify="center">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="agreementHeaders"
              :items="agreements"
              :search="search"
              :footer-props="{
                itemsPerPageOptions: [30, 60, 90, 120, 150],
                'items-per-page-text': 'Agreements per page:',
                'show-current-page': true,
                'show-first-last-page': true,
              }"
            >
              <template #item.date_start="{ item }">
                {{ formatDate(item.date_start) }}
              </template>
              <template #item.date_end="{ item }">
                {{ formatDate(item.date_end) }}
              </template>
              <template #item.agreementUrl="{ item }">
                <div v-if="item.agreementUrl">
                  <v-btn :href="item.agreementUrl" download target="_blank"
                    >View Agreement</v-btn
                  >
                </div>
                <div v-else>Null</div>
              </template>
              <template #item.whatsappUrl="{ item }">
                <div v-if="item.whatsappUrl">
                  <a :href="item.whatsappUrl" target="_blank">
                    <v-icon>mdi-whatsapp</v-icon>
                  </a>
                </div>
                <div v-else>Null</div>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-icon
                  v-if="item.status != 'Editted'"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  small
                  class="mr-2"
                  @click="editBooking(item._id)"
                >
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </div>
        <div v-if="showAvailableCars">
          <v-card class="grid" color="transparent">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field
          ></v-card>
          <v-data-table
            :headers="availableCarHeaders"
            :items="activeCars"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [30, 60, 90, 120, 150],
              'items-per-page-text': 'Cars per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
          >
            <template v-slot:item.isAvailable="{ item }">
              <v-simple-checkbox
                v-model="item.isAvailable"
                :disabled="item.isActive"
                @click="carAvailable(item._id)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </div>
        <div v-if="showUsers">
          <v-card class="grid" color="transparent">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-card>
          <v-data-table
            :headers="userHeaders"
            :items="users"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [30, 60, 90, 120, 150],
              'items-per-page-text': 'Users per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
          >
            <template #item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.isActive="{ item }">
              <v-simple-checkbox
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                v-model="item.isActive"
                :disabled="item.isActive"
                @click="activateUser(item._id)"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                small
                class="mr-2"
                @click="editUser(item._id)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <div v-if="showCars">
          <v-card class="grid" color="transparent">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field
          ></v-card>
          <v-row align="center"
            ><v-col :cols="4" align="center"
              ><v-btn
                dark
                class="button"
                style="margin-top: 2% !important; margin-bottom: 2% !important"
                @click="addCar"
              >
                <v-icon
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                >
                  mdi-card-plus-outline mdi-18px
                </v-icon>
                Add Car
              </v-btn></v-col
            ><v-col :cols="4" align="center"
              ><v-btn
                dark
                class="btnWa"
                style="margin-top: 2% !important; margin-bottom: 2% !important"
                @click="showCarMakeForm = true"
              >
                <v-icon
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                >
                  mdi-card-plus-outline mdi-18px
                </v-icon>
                Add Car Make & Model
              </v-btn></v-col
            ></v-row
          >
          <v-dialog v-model="showCarMakeForm">
            <v-card>
              <v-card-title>
                Send Email
                <v-spacer></v-spacer>
                <v-btn dark icon @click="showCarMakeForm = false">
                  <v-icon>mdi-close mdi-18px</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-text-field v-model="newMake" label="New Make"></v-text-field>
                <v-card-actions>
                  <v-btn dark @click="addCarMake" class="btnWa">
                    <v-icon
                      style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      >mdi-plus mdi-18px</v-icon
                    >Add New Make
                  </v-btn>
                </v-card-actions>
              </v-card-text>
              <v-card-text>
                <v-select
                  v-model="make"
                  :items="makes"
                  label="Makes"
                ></v-select>
                <v-text-field
                  v-model="newModel"
                  label="New Model"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn dark class="button" @click="addCarModel"
                  ><v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    >mdi-plus</v-icon
                  >Add New Model</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="carHeaders"
            :items="cars"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [30, 60, 90, 120, 150],
              'items-per-page-text': 'Cars per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
          >
            <template v-slot:item.edit="{ item }">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                small
                class="mr-2"
                @click="select(item._id)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <!-- <div v-if="showBookings">
          <v-card class="grid" color="transparent">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-row align="center">
              <v-col :cols="4" align="center"
                ><v-btn class="btnWa" @click="addBooking">
                  <v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >
                    mdi-card-plus-outline mdi-18px
                  </v-icon>
                  Add Booking
                </v-btn></v-col
              >
              <v-col :cols="4" align="center">
                <v-btn class="btnWa" @click="advancedFilter">
                  <v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >
                    mdi-card-plus-outline mdi-18px
                  </v-icon>
                  Advanced Filter
                </v-btn></v-col
              ></v-row
            ></v-card
          >
          <v-data-table
            :headers="bookingHeaders"
            :items="bookings"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [30, 60, 90, 120, 150],
              'items-per-page-text': 'Bookings per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
          >
            <template #item.reservationDateStart="{ item }">
              {{ formatDate(item.reservationDateStart) }}
            </template>
            <template #item.reservationDateEnd="{ item }">
              {{ formatDate(item.reservationDateEnd) }}
            </template>
            <template v-slot:item.edit="{ item }">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                small
                class="mr-2"
                @click="editBooking(item._id)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div> -->
        <!-- <div v-if="showExpenses">
          <v-card-text class="text">
            <label for="filterDate">Filter by Date:</label></v-card-text
          >
          <v-card-text class="text"
            ><input type="date" v-model="filterDate" id="filterDate"
          /></v-card-text>
          <v-card-text class="text"
            >Total expense on selected date: {{ totalExpense }}</v-card-text
          >
          <v-card-text class="text">
            <ul>
              <li v-for="expense in filteredExpenses" :key="expense.id">
                {{ formatDate(expense.startDate) }} - {{ expense.totalPrice }} -
                {{ expense.name }} -
                {{ expense.creator }}
              </li>
            </ul>
          </v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-text class="d-flex align-center justify-center about"
                  >Expenses</v-card-text
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card class="grid" color="transparent">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card>
                <v-data-table
                  :headers="expensesHeaders"
                  :items="expenses"
                  :search="search"
                  :footer-props="{
                    itemsPerPageOptions: [30, 60, 90, 120, 150],
                    'items-per-page-text': 'Expenses per page:',
                    'show-current-page': true,
                    'show-first-last-page': true,
                  }"
                >
                  <template #item.startDate="{ item }">
                    {{ formatDate(item.startDate) }}
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-icon small class="mr-2" @click="editExpenses(item._id)">
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row align="center" justify="center">
            <v-btn
              dark
              class="button"
              @click="addExpenses = true"
              style="margin-top: 2% !important; margin-bottom: 2% !important"
            >
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-account-plus-outline mdi-18px
              </v-icon>
              Add Expense
            </v-btn>
          </v-row>
          <div v-if="addExpenses">
            <v-card shaped class="grid">
              <v-card-text class="d-flex align-center justify-center about"
                >Expense Logger</v-card-text
              >
              <form @submit.prevent="addExpense">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card-text class="text">Car Details</v-card-text>
                    <v-select
                      dark
                      v-model="expense.carId"
                      :rules="[rules.required]"
                      :items="cars"
                      item-text="plateNumber"
                      item-value="_id"
                      required
                      class="text"
                      dense
                      solo
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-card-text class="text">Expense Date</v-card-text>
                    <v-text-field
                      dark
                      v-model="expense.startDate"
                      solo
                      required
                      :rules="[rules.required]"
                      outlined
                      dense
                    ></v-text-field>
                    <v-date-picker
                      v-model="expense.startDate"
                      @input="setStartDate"
                      dense
                      solo
                      width="90%"
                      height="20%"
                      dark
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card-text class="text">Price Paid (RM)</v-card-text>
                    <v-text-field
                      dark
                      v-model="expense.totalPrice"
                      solo
                      required
                      :rules="[rules.required]"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card-text class="text"
                      >Expense Details (eg : Petrol)</v-card-text
                    >
                    <v-text-field
                      dark
                      v-model="expense.name"
                      solo
                      :rules="[rules.required]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center align-center" no-gutters>
                  <v-btn dark class="button" type="submit">
                    <v-icon
                      style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    >
                      mdi-account-plus-outline mdi-18px
                    </v-icon>
                    Submit
                  </v-btn>
                </v-row>
              </form>
            </v-card>
          </div>
        </div> -->
        <div v-if="showTrackers">
          <v-card-text class="text">
            <label for="filterDate">Filter by Date:</label></v-card-text
          >
          <v-card-text class="text"
            ><input type="date" v-model="filterDate" id="filterDate"
          /></v-card-text>
          <v-card-text class="text">
            <ul>
              <li v-for="tracker in filteredTrackers" :key="tracker._id">
                {{ tracker.data }} - {{ tracker.count }}
              </li>
            </ul>
          </v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-text class="d-flex align-center justify-center about"
                  >Trackers</v-card-text
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card class="grid" color="transparent">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card>
                <v-data-table
                  :headers="trackerHeaders"
                  :items="trackers"
                  :search="search"
                  :footer-props="{
                    itemsPerPageOptions: [30, 60, 90, 120, 150],
                    'items-per-page-text': 'Trackers per page:',
                    'show-current-page': true,
                    'show-first-last-page': true,
                  }"
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="showMarketting">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-text class="d-flex align-center justify-center about"
                  >Send Whatsapp Messages</v-card-text
                >
                <v-card-text class="d-flex align-center justify-center about"
                  >Total Users : {{ count.total }}</v-card-text
                ><v-card-text class="d-flex align-center justify-center about"
                  >Total Users Received Whatsapp :
                  {{ count.totalSent }}</v-card-text
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card class="grid" color="transparent">
                  <v-row align="center">
                    <v-col md="6" cols="12"
                      ><v-card-text class="text">Title </v-card-text
                      ><v-text-field
                        v-model="title"
                        :rules="[rules.required]"
                        required
                        class="text"
                        dense
                        solo
                      ></v-text-field
                    ></v-col>
                    <v-col md="6" cols="12"
                      ><v-card-text class="text">Image name</v-card-text
                      ><v-text-field
                        v-model="filename"
                        :rules="[rules.required]"
                        required
                        class="text"
                        dense
                        solo
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col md="12" cols="12"
                      ><v-card-text class="text"
                        >Upload Content Here :
                      </v-card-text>
                      <v-file-input
                        :rules="[rules.required]"
                        prepend-icon="mdi-image-outline"
                        accept="image/*"
                        @change="content" />
                      <div v-if="imageUrl">
                        <v-card class="grid" width="300px" height="300px">
                          <v-row
                            justify="center"
                            align="center"
                            style="height: 61px !important"
                          >
                            <v-btn class="rounded-btn" @click="removeUrl()">
                              x
                            </v-btn></v-row
                          >
                          <v-img
                            :src="imageUrl"
                            max-height="300px"
                            max-width="300px"
                            contain
                          >
                          </v-img
                        ></v-card></div
                    ></v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      md="12"
                      cols="12"
                      v-if="showUploadButton"
                      align="center"
                      ><v-btn dark @click="allowUpload()" class="btnWa">
                        Upload New Contacts
                      </v-btn></v-col
                    >
                    <v-col md="12" cols="12" v-if="showUpload" align="center"
                      ><v-card-text class="text">Upload CSV Here </v-card-text
                      ><v-file-input
                        label="Upload Users Bulk"
                        persistent-hint
                        hint="The format of the file should be csv. The header in the file should be Phone"
                        accept=".csv"
                        @change="selectFile"
                        :rules="[(v) => !!v || 'File is required']"
                      />
                      <v-row align="center">
                        <v-col md="12" cols="12" align="center"
                          ><v-btn dark @click="cancelUpload()" class="btnWa">
                            Cancel Upload
                          </v-btn></v-col
                        >
                      </v-row></v-col
                    >
                  </v-row>
                  <!-- {{ mobile.length }} -->
                  <!-- {{ mobile }} -->
                  <v-row align="center" style="padding-top: 2px !important">
                    <v-col md="12" cols="12" align="center">
                      <v-btn
                        dark
                        @click="sendWhatsappTestMessage()"
                        class="btnWa"
                      >
                        Send Test Message
                      </v-btn>
                    </v-col>
                    <v-col md="12" cols="12" align="center">
                      <v-btn dark @click="sendWhatsappMessage()" class="btnWa">
                        Send to all users
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
import moment from "moment";
var csvjson = require("csvjson");

export default {
  data() {
    return {
      name: this.$store.state.userProfile.name,
      showCarMakeForm: false,
      // tab: 'web',
      showUsers: false,
      users: [],
      currentDate: null,
      userHeaders: [
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email", sortable: false, align: "center" },
        {
          text: "Contact Number",
          value: "mobileNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "Identification / Passport Number",
          value: "primaryIdentificationNum",
          sortable: false,
          align: "center",
        },
        {
          text: "User Join Date",
          value: "created_at",
          align: "center",
        },
        {
          text: "Active User?",
          value: "isActive",
          align: "center",
          filterable: false,
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ],
      search: "",
      showCars: false,
      cars: [],
      carHeaders: [
        {
          text: "Number Plate",
          align: "center",
          sortable: false,
          value: "plateNumber",
        },
        { text: "Status", align: "center", value: "rentStatus" },
        {
          text: "Make",
          align: "center",
          value: "make",
        },
        {
          text: "Model",
          sortable: false,
          align: "center",
          value: "model",
        },
        {
          text: "Price Per Day",
          align: "center",
          filterable: false,
          value: "pricePerDay",
        },
        {
          text: "Price 3 Days",
          align: "center",
          filterable: false,
          value: "pricePer3days",
        },
        {
          text: "Price Per Week",
          align: "center",
          filterable: false,
          value: "pricePerWeek",
        },
        {
          text: "Price Per Month",
          align: "center",
          filterable: false,
          value: "pricePerMonth",
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ],
      extended: false,
      showBookings: false,
      bookings: [],
      bookingHeaders: [
        {
          text: "Number Plate",
          align: "center",
          sortable: false,
          value: "plateNumber",
        },
        {
          text: "Start Date",
          align: "center",
          value: "reservationDateStart",
        },
        {
          text: "End Date",
          sortable: false,
          align: "center",
          value: "reservationDateEnd",
        },
        {
          text: "Net Price (RM)",
          align: "center",
          filterable: false,
          value: "totalPrice",
        },
        {
          text: "Agent Name",
          align: "center",
          filterable: false,
          value: "agentName",
        },
        {
          text: "Agent Commisions",
          align: "center",
          filterable: false,
          value: "agentCommisions",
        },
        {
          text: "Extended Booking?",
          align: "center",
          filterable: false,
          value: "extensionEndDate",
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ],
      expensesHeaders: [
        {
          text: "Number Plate",
          align: "center",
          sortable: false,
          value: "plateNumber",
        },
        {
          text: "Start Date",
          align: "center",
          value: "startDate",
        },
        {
          text: "Expense Amount",
          sortable: false,
          align: "center",
          value: "totalPrice",
        },
        {
          text: "Expense Name",
          align: "center",
          filterable: false,
          value: "name",
        },
        {
          text: "Admin Email",
          align: "center",
          filterable: false,
          value: "creator",
        },
      ],
      expenses: [],
      totalSales: 0,
      showExpenses: false,
      expense: {
        startDate: null,
      },
      filterDate: "",
      startDate: null,
      addExpenses: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      showTrackers: false,
      trackerHeaders: [
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Button Name",
          align: "center",
          value: "buttonName",
        },
        {
          text: "Data Type",
          sortable: false,
          align: "center",
          value: "dataType",
        },
        {
          text: "Mobile (IF USER)",
          align: "center",
          value: "mobileNumber",
        },
        {
          text: "Clicked at",
          align: "center",
          value: "created_at",
        },
      ],
      trackers: [],
      showAvailableCars: false,
      availableCarHeaders: [
        {
          text: "Number Plate",
          align: "center",
          sortable: false,
          value: "plateNumber",
        },
        {
          text: "Make",
          align: "center",
          value: "make",
        },
        {
          text: "Model",
          sortable: false,
          align: "center",
          value: "model",
        },
        {
          text: "Available Today",
          align: "center",
          value: "isAvailable",
        },
      ],
      list: [],
      makes: [],
      newModel: "",
      newMake: "",
      make: "",
      showAgreements: true,
      agreementHeaders: [
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Number Plate",
          align: "center",
          value: "number_plate",
        },
        {
          text: "ID",
          sortable: false,
          align: "center",
          value: "id_number",
        },
        {
          text: "Mobile Number",
          align: "center",
          value: "mobile",
        },
        {
          text: "Total Price (RM)",
          sortable: false,
          align: "center",
          value: "total_price",
        },
        {
          text: "Start Date",
          align: "center",
          value: "date_start",
        },
        {
          text: "End Date",
          align: "center",
          value: "date_end",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
          value: "status",
        },
        {
          text: "Creator",
          sortable: false,
          align: "center",
          value: "creator",
        },
        {
          text: "Agreement",
          sortable: false,
          align: "center",
          value: "agreementUrl",
        },
        {
          text: "Whatsapp",
          sortable: false,
          align: "center",
          value: "whatsappUrl",
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ],
      agreements: [],
      showMarketting: false,
      title: "",
      mobileNumber: "",
      imageUrl: null,
      filename: "",
      mobile: [],
      showUploadButton: true,
      showUpload: false,
      count: [],
    };
  },
  mounted: function () {
    this.getAgreements();
    this.currentDate = new Date().toISOString().substr(0, 10);
  },
  computed: {
    activeCars() {
      return this.cars.filter((car) => {
        return car.rentStatus === "Active";
      });
    },
    // filteredExpenses() {
    //   if (this.filterDate) {
    //     return this.expenses.filter((expense) => {
    //       return expense.startDate.substr(0, 10) === this.filterDate;
    //     });
    //   } else {
    //     return [];
    //   }
    // },
    // totalExpense() {
    //   return this.filteredExpenses.reduce((total, expense) => {
    //     return total + Number(expense.totalPrice);
    //   }, 0);
    // },
    filteredTrackers() {
      // Filter trackers based on the specified date
      const filtered = this.trackers.filter((tracker) => {
        return tracker.created_at === this.filterDate;
      });

      // Count the occurrences of each dataType
      const countMap = {};
      filtered.forEach((tracker) => {
        const dataType = tracker.dataType;
        countMap[dataType] = countMap[dataType] ? countMap[dataType] + 1 : 1;
      });

      // Sort the filtered trackers by the count of dataType
      const sorted = filtered.sort((a, b) => {
        const countA = countMap[a.dataType];
        const countB = countMap[b.dataType];

        // Sort by count first
        if (countB !== countA) {
          return countB - countA; // Sort in descending order of count
        }

        // If counts are the same, sort by dataType alphabetically
        return a.dataType.localeCompare(b.dataType);
      });

      // Create an array of objects with tracker and count
      const sortedWithCount = sorted.map((tracker) => {
        return {
          data: tracker.dataType,
          count: countMap[tracker.dataType],
        };
      });

      const array = sortedWithCount;
      const uniqueArray = array.filter((obj, index, self) => {
        return index === self.findIndex((o) => o.data === obj.data);
      });

      return uniqueArray;
    },
  },

  methods: {
    loadTab(a) {
      if (a == "Users") {
        this.showUsers = true;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = false;
        this.showAvailableCars = false;
        this.showAgreements = false;
        this.showMarketting = false;
        this.getUsers();
      } else if (a == "Cars") {
        this.showUsers = false;
        this.showCars = true;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = false;
        this.showAvailableCars = false;
        this.showAgreements = false;
        this.showMarketting = false;
        this.getCars();
      } else if (a == "Bookings") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = true;
        this.showExpenses = false;
        this.showTrackers = false;
        this.showAvailableCars = false;
        this.showAgreements = false;
        this.showMarketting = false;
      } else if (a == "Trackers") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = true;
        this.showAvailableCars = false;
        this.showAgreements = false;
        this.showMarketting = false;
        this.getTrackers();
      } else if (a == "Available Cars") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = false;
        this.showAvailableCars = true;
        this.showAgreements = false;
        this.showMarketting = false;
        this.getCars();
      } else if (a == "Agreements") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = false;
        this.showTrackers = false;
        this.showAvailableCars = false;
        this.showAgreements = true;
        this.showMarketting = false;
        this.getAgreements();
      } else if (a == "Marketting") {
        this.showUsers = false;
        this.showCars = false;
        this.showBookings = false;
        this.showExpenses = true;
        this.showTrackers = false;
        this.showAvailableCars = false;
        this.showAgreements = false;
        this.showMarketting = true;
        this.getCount();
        // this.getExpenses();
      }
    },
    async getUsers() {
      this.$setLoader();
      await dataService.fetchUsers().then((res) => {
        this.users = res.data.users;
      });
      await dataService.scheduler().then((res) => {
        console.log(res);
      });
      this.$disableLoader();
    },

    async getCars() {
      this.$setLoader();
      await dataService.getAllCars().then((res) => {
        this.cars = res.data.cars;
      });

      this.$disableLoader();
    },

    async getMakess() {
      this.$setLoader();
      await dataService.getCarData().then((res) => {
        this.list = res.data.getCarData;
        this.getMakes();
      });

      this.$disableLoader();
    },

    getMakes() {
      this.$setLoader();
      for (let m of this.list) {
        this.makes.push(m.make);
      }
      this.$disableLoader();
    },

    async addCarMake() {
      this.$setLoader();
      await dataService.addMake({ make: this.newMake }).then((res) => {
        console.log(res);
      });

      this.$disableLoader();
      this.showCarMakeForm = false;
    },

    async addCarModel() {
      this.$setLoader();
      await dataService
        .addModel({ make: this.make, model: this.newModel })
        .then((res) => {
          console.log(res);
        });

      this.$disableLoader();
      this.showCarMakeForm = false;
    },

    // async getBookings() {
    //   this.$setLoader();
    //   await dataService.getBookings().then((res) => {
    //     this.bookings = res.data.getBookings;
    //     for (let booking of this.bookings) {
    //       if (!booking.extentensionPrice) {
    //         booking.extentensionPrice = 0;
    //       }
    //       this.totalSales +=
    //         Number(booking.totalPrice) + Number(booking.extentensionPrice);
    //       if (
    //         booking.extensionEndDate != undefined ||
    //         booking.extensionEndDate != null
    //       ) {
    //         booking.extensionEndDate = true;
    //       } else {
    //         booking.extensionEndDate = false;
    //       }
    //     }
    //   });

    //   this.$disableLoader();
    // },

    content(file) {
      if (file) {
        this.$setLoader();
        // let cloudFile = {};
        // cloudFile.file = file;
        const result = Math.random().toString(36).substring(2, 7);
        // console.log(result);
        file.resource_type = "image";
        file.filename = `${this.filename}${result}`;
        console.log(file.filename);
        this.uploadFileToCloudinary(file).then((fileResponse) => {
          console.log(fileResponse);
          // Build the Cloudinary URL with transformation parameters
          this.imageUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${fileResponse.public_id}.${fileResponse.format}`;
          this.$disableLoader();
        });
      }
    },

    uploadFileToCloudinary(file) {
      // console.log(file);
      // file.name = `${this.name}_${this.id_number}_${this.car_type}`;
      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_PRESET1
        );
        // formData.append("resource_type", file.resource_type);
        formData.append("file", file);
        formData.append("public_id", file.filename);
        formData.append("folder", "jrvposts");
        let request = new XMLHttpRequest();
        request.open(
          "POST",
          `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/upload`,
          true
        );
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },

    async getExpenses() {
      this.$setLoader();
      await dataService.getExpenses().then((res) => {
        this.expenses = res.data.expenses;
      });

      this.$disableLoader();
    },

    async getTrackers() {
      this.$setLoader();
      await dataService.getTrackers().then((res) => {
        this.trackers = res.data.trackers;
      });

      this.$disableLoader();
    },

    async getAgreements() {
      this.$setLoader();

      if (this.$store.state.user.roles[0] == "admin") {
        await dataService.getAgreements().then((res) => {
          this.agreements = res.data.agreements;
          for (let agreement of this.agreements) {
            if (agreement.status == "New")
              this.totalSales += Number(agreement.total_price);
          }
        });
        // await dataService.scheduler().then((res) => {
        //   console.log(res);
        // });
      } else {
        alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
        this.$router.push({ path: `/` });
      }

      this.$disableLoader();
    },

    // async addExpense() {
    //   this.$setLoader();
    //   for (let car of this.cars) {
    //     if (this.expense.carId == car._id) {
    //       this.expense.plateNumber = car.plateNumber;
    //     }
    //   }
    //   this.expense.creator = this.$store.state.userProfile.email;
    //   await dataService.addExpenses(this.expense).then((res) => {
    //     console.log(res);
    //     window.location.reload();
    //   });

    //   this.$disableLoader();
    // },

    formatDate(dateString) {
      // const date = new Date(dateString);
      // const options = { timeZone: 'UTC' };
      // return date.toLocaleString('en-US', options);
      if (dateString != null) {
        const newDate = dateString;
        const date = moment(newDate).format("LLLL");
        return date;
      } else {
        return null;
      }
    },

    async activateUser(id) {
      this.$setLoader();
      await dataService.activateUser({ _id: id }).then(() => {
        this.getUsers();
      });

      this.$disableLoader();
    },

    async carAvailable(id) {
      this.$setLoader();
      await dataService.availableCar({ _id: id }).then(() => {
        this.getCars();
      });

      this.$disableLoader();
    },

    addCar() {
      this.$setLoader();
      this.$router.push({ path: `/addCar` });
      this.$disableLoader();
    },

    createPdf() {
      let data = {};
      this.$router.push({ name: "agreement", params: { data } });
    },

    addBooking() {
      this.$setLoader();
      this.$router.push({ path: `/addBooking` });
      this.$disableLoader();
    },

    advancedFilter() {
      this.$setLoader();
      this.$router.push({ path: `/bookings` });
      this.$disableLoader();
    },

    select(cardId) {
      this.$setLoader();
      this.$router.push({ path: `/car/${cardId}` });
      this.$disableLoader();
    },

    editUser(cardId) {
      this.$setLoader();
      this.$router.push({ path: `/editUser/${cardId}` });
      this.$disableLoader();
    },

    editBooking(cardId) {
      this.$setLoader();
      this.$router.push({ path: `/editBooking/${cardId}` });
      this.$disableLoader();
    },

    setStartDate(newValue) {
      this.startDate = newValue;
      this.expense.startDate = newValue;
    },

    async sendWhatsappMessage() {
      this.$setLoader();
      await dataService
        .sendWhatsapp({
          mobile: this.mobile,
          title: this.title,
          url: this.imageUrl,
        })
        .then((res) => {
          if (res.status == 200) {
            alert(res.data.message);
            this.getCount();
          } else {
            console.log(res);
            this.getCount();
          }
        });

      this.$disableLoader();
    },

    async sendWhatsappTestMessage() {
      this.$setLoader();
      await dataService
        .sendWhatsapp({
          title: this.title,
          url: this.imageUrl,
          test: "yes",
        })
        .then((res) => {
          if (res.status == 200) {
            alert(res.data.message);
            window.location.reload();
          } else {
            console.log(res);
            window.location.reload();
          }
        });

      this.$disableLoader();
    },

    async getCount() {
      this.$setLoader();
      await dataService.getCount().then((res) => {
        this.count = res.data.WhatsappCount;
        this.$disableLoader();
      });
    },

    removeUrl() {
      this.imageUrl = null;
      this.contentUrl = null;
      this.filename = "";
    },

    selectFile(e) {
      if (e) {
        const self = this;
        const file = e;
        this.buttonText = file.name;

        let reader = new FileReader();
        reader.onload = () => {
          self.importedData = reader.result;

          let csvData = csvjson.toObject(reader.result, {});
          this.mobile = this.retreiveMobileUsers(csvData);
          alert(`Are you sure to add this numbers? ${this.mobile}`);
          // console.log(this.mobile);
        };
        reader.readAsText(file);
      }
    },

    retreiveMobileUsers(csvData) {
      // console.log(csvData)
      let processedMobileNumbers = [];
      // console.log(csvData);
      // ensure Phone is changed in main file
      csvData.forEach((row) => {
        row.toString();
        // console.log(row.Phone == undefined);
        if (row.Phone != null && row.Phone != undefined && row.Phone != "") {
          // console.log(row.Phone)
          if (
            row.Phone.startsWith("6", 2) ||
            row.Phone.startsWith("6", 0) ||
            (row.Phone.startsWith("+", 0) && !row.Phone.startsWith("E", 8))
          ) {
            row.Phone.replaceAll(" ", "");
            row.Phone.split(",")[0];
            row.Phone = row.Phone.replace(/[^+\d+]/g, "");

            if (row.Phone.startsWith("+")) {
              console.log(row.Phone.length);
              processedMobileNumbers.push(row.Phone);
            }
          }
        }
      });
      let unique = [];
      processedMobileNumbers.forEach((element) => {
        if (!unique.includes(element)) {
          unique.push(element);
        }
      });
      return unique;
      // return [...new Set(processedMobileNumbers)];
    },

    allowUpload() {
      this.showUploadButton = false;
      this.showUpload = true;
    },

    cancelUpload() {
      this.showUploadButton = true;
      this.showUpload = false;
      this.mobile = [];
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}
#particles-js {
  background-color: #f9ece0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
}
</style>
